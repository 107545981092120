import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserRole } from '../models/auth.models';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  handleError: any;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, protected authSrv: AuthService) {}

  getUsers(pageSize: number, page: number, search?: string, filters?: any, sort?: any) {
    let params = new HttpParams();

    params = params.appendAll({
      pageSize,
      page,
      relations: 'company',
    });

    if (search && search != '') {
      params = params.appendAll({
        searchFields: 'name,email',
        search,
      });
    }

    if (filters && Object.keys(filters).length) {
      if (filters.role) {
        params = params.append('query[role]', filters.role);
      }
      if (filters.nif) {
        params = params.append('query[nif]', filters.nif);
      }
    }

    if (sort && Object.keys(sort).length) {
    } else {
      params = params.append('sort', 'id:ASC');
    }

    let uri = `${environment.API_URL}/api/user`;
    return this.http.get<any[]>(uri, { params });
  }

  getAdministrators(pageSize: number, page: number, search?: string, filters?: any, sort?: any) {
    let params = new HttpParams();

    params = params.appendAll({
      pageSize,
      page,
      relations: 'company',
    });

    if (search && search != '') {
      if (this.authSrv.currentUser?.isAdmin) {
        params = params.appendAll({
          searchFields: 'name,email',
          search,
        });
      } else {
        params = params.appendAll({
          searchFields: 'name,email,company.name',
          search,
        });
      }
    }

    if (this.authSrv.currentUser?.isAdmin && this.authSrv.currentUser?.companyId) {
      params = params.appendAll({
        'query[companyId]': this.authSrv.currentUser?.companyId,
        'query[role]': UserRole.ADMIN,
      });
    } else if (this.authSrv.currentUser?.isAntram) {
      params = params.append('queryArrayEnum[role]', 'ANTRAM,ADMIN');
    }

    if (!this.authSrv.currentUser?.isAdmin) {
      if (filters && filters.role) {
        params = params.append('query[role]', filters.role);
      } else {
        params = params.append('queryNot[role]', UserRole.USER);
      }
    }

    if (filters && Object.keys(filters).length) {
      if (filters.accountActive !== undefined && filters.accountActive !== null) {
        params = params.append('query[accountActive]', filters.accountActive);
      }
    }

    if (sort && Object.keys(sort).length) {
    } else {
      if (this.authSrv.currentUser?.isAntram) {
        params = params.append('sort', 'role:ASC,name:ASC');
      } else {
        params = params.append('sort', 'name:ASC');
      }
    }

    let uri = `${environment.API_URL}/api/user`;
    return this.http.get<any[]>(uri, { params });
  }

  getAdministratorsNotDrivers(pageSize: number, page: number, search?: string, filters?: any, sort?: any) {
    let params = new HttpParams().appendAll({
      pageSize,
      page,
      relations: ['company'].join(),
    });

    if (this.authSrv.currentUser?.isAdmin && this.authSrv.currentUser?.companyId) {
      if (!filters) filters = {};
      filters.companyId = this.authSrv.currentUser.companyId;
      filters.role = UserRole.ADMIN;
    }

    if (search && search != '') {
      params = params.appendAll({
        searchFields: 'name',
        search,
      });
    }

    if (filters && Object.keys(filters).length) {
      if (filters.companyId) {
        params = params.append('query[companyId]', filters.companyId);
      }
      if (filters.role) {
        params = params.append('query[role]', filters.companyId);
      }
    }

    if (sort && Object.keys(sort).length) {
    } else {
      params = params.append('sort', 'id:ASC');
    }

    let uri = `${environment.API_URL}/api/user/adminsNotDrivers`;
    return this.http.get<any[]>(uri, { params });
  }

  getColaborators(pageSize: number, page: number, search?: string, filters?: any, sort?: any) {
    let params = new HttpParams();
    params = params.appendAll({
      pageSize,
      page,
      relations: ['companyUsers'].join(),
    });

    if (search && search != '') {
      params = params.appendAll({
        searchFields: 'name',
        search,
      });
    }

    if (filters && Object.keys(filters).length) {
      if (filters.companyId) {
        params = params.appendAll({
          'query[companyUsers.companyId]': filters.companyId,
        });
      }
    }

    if (sort && Object.keys(sort).length) {
    } else {
      params = params.append('sort', 'name:ASC');
    }

    let uri = `${environment.API_URL}/api/user`;
    return this.http.get<any[]>(uri, { params });
  }

  getAllUsers() {
    let params = new HttpParams().appendAll({
      sort: 'name:ASC',
    });

    let uri = `${environment.API_URL}/api/user`;
    return this.http.get<any[]>(uri, { params });
  }

  getUser(userId: string) {
    let params = new HttpParams().appendAll({
      relations: ['company', 'company.country'].join(),
    });

    let uri = `${environment.API_URL}/api/user/${userId}`;
    return this.http.get<any[]>(uri, { params });
  }

  resetNifPin(userId: string) {
    let uri = `${environment.API_URL}/api/user/resetNifPin`;
    return this.http.patch<any[]>(uri, { userId });
  }

  getUserByEmail(email: string) {
    let params = new HttpParams().appendAll({
      'query[email]': email,
    });

    let uri = `${environment.API_URL}/api/user`;
    return this.http.get<any[]>(uri, { params });
  }

  createUser(user: any): any {
    let uri = `${environment.API_URL}/api/user`;
    return this.http.post<any>(uri, user, { headers: this.headers });
  }

  createAdmin(user: any): any {
    let uri = `${environment.API_URL}/api/user/admin`;
    return this.http.post<any>(uri, user, { headers: this.headers });
  }

  updateUser(id: string, data: any): any {
    let uri = `${environment.API_URL}/api/user/${id}`;
    return this.http.patch<any>(uri, data, { headers: this.headers });
  }

  deleteUser(userId: string): any {
    let uri = `${environment.API_URL}/api/user/${userId}`;
    return this.http.delete<any>(uri, { headers: this.headers });
  }

  reactivateUser(userId: string): any {
    let uri = `${environment.API_URL}/api/user/${userId}/reactivate`;
    return this.http.patch<any>(uri, { headers: this.headers });
  }

  defineActiveCompany(data: any) {
    let uri = `${environment.API_URL}/api/user/activeCompany`;
    return this.http.patch<any>(uri, data, { headers: this.headers });
  }

  //** Password or Pin **//
  updatePassword(data: any) {
    let uri = `${environment.API_URL}/api/user/password`;
    return this.http.patch<any>(uri, data, { headers: this.headers });
  }

  updatePin(data: any) {
    let uri = `${environment.API_URL}/api/user/pin`;
    return this.http.patch<any>(uri, data, { headers: this.headers });
  }

  //** Profile **//
  getProfile() {
    let params = new HttpParams().appendAll({
      relations: 'country',
    });

    let uri = `${environment.API_URL}/api/user/profile`;
    return this.http.get<any[]>(uri, { params });
  }

  updateProfile(data: any) {
    let uri = `${environment.API_URL}/api/user/profile`;
    return this.http.patch<any>(uri, data, { headers: this.headers });
  }

  acceptPrivacy() {
    let uri = `${environment.API_URL}/api/user/acceptPrivacy`;
    return this.http.patch<any>(uri, {}, { headers: this.headers });
  }

  resendActivationEmail(userId: any): any {
    let uri = `${environment.API_URL}/api/user/resendActivationEmail`;
    return this.http.post<any>(uri, { userId }, { headers: this.headers });
  }
}
