import { User } from './auth.models';
import { Configuration } from './configuration.models';
import { Country } from './country.models';

export enum CompanyStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  ANALYSIS = 'ANALYSIS',
  DELETED = 'DELETED',
  CONSULT = 'CONSULT',
}

export enum AntramSIStatus {
  CHECK = 'CHECK',
  NOTCHECK = 'NOTCHECK',
  LOADING = 'LOADING',
}

export enum AlertRenovationEnum {
  SHOW = 'SHOW',
  NOTSHOW = 'NOTSHOW',
  LOADING = 'LOADING',
}

export enum EntityTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  COLLECTIVE = 'COLLECTIVE',
}

export enum GuideEntityType {
  EXPEDITOR = 'EXPEDITOR',
  TRANSPORTER = 'TRANSPORTER',
}

export class Company {
  id: string;
  nif: string | null;
  name: string | null;
  email: string | null;
  contact: string | null;
  address: string | null;
  establishment: string | null;
  stablishment: string | null;
  locality: string | null;
  postalCode: string | null;
  status: CompanyStatus;
  licensesToRequest: number | null;
  entityType: EntityTypes;
  countryId: number;
  establishmentId: number;
  isDemo: boolean;
  isPassiveSubject: boolean;
  isProtocol: boolean;
  isVatIsention: boolean;
  ignoreAnualSubscription: boolean;
  isTransporter: boolean;
  isShipper: boolean;
  licenseNumber: string | null;

  rittSubscribed: boolean;
  eGuidesSubscribed: boolean;

  createdById: string | null;
  updatedById: string | null;
  approvedById: string | null;
  partnerId: string | null;
  costCenterId: string | null;
  renewalDate: Date | null;

  createdBy: User | null;
  updatedBy: User | null;
  approvedBy: User | null;
  analysesSetBy: User | null;
  partner: any | null;
  costCenter: Configuration | null;
  country: Country | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj?.id ?? '';

    this.nif = $obj?.nif ?? null;
    this.name = $obj?.name ?? null;
    this.email = $obj?.email ?? null;
    this.contact = $obj?.contact ?? null;
    this.address = $obj?.address ?? null;
    this.establishment = $obj?.establishment ?? null;
    this.stablishment = $obj?.stablishment ?? null;
    this.locality = $obj?.locality ?? null;
    this.postalCode = $obj?.postalCode ?? null;
    this.status = $obj?.status ?? CompanyStatus.PENDING;
    this.licensesToRequest = $obj?.licensesToRequest ?? null;
    this.entityType = $obj?.entityType ?? null;
    this.countryId = $obj?.countryId ?? null;
    this.establishmentId = $obj?.establishmentId ?? null;
    this.isDemo = $obj?.isDemo ?? false;
    this.isTransporter = $obj?.isTransporter ?? false;
    this.isShipper = $obj?.isShipper ?? false;
    this.licenseNumber = $obj?.licenseNumber ?? null;
    this.isVatIsention = $obj?.isVatIsention ?? false;
    this.isProtocol = $obj?.isProtocol ? $obj.isProtocol : false;
    this.isPassiveSubject = $obj?.isPassiveSubject ?? false;
    this.ignoreAnualSubscription = $obj?.ignoreAnualSubscription ?? false;

    this.rittSubscribed = $obj?.rittSubscribed ?? false;
    this.eGuidesSubscribed = $obj?.eGuidesSubscribed ?? false;

    this.createdById = $obj?.createdById ?? $obj?.createdBy?.id ?? null;
    this.updatedById = $obj?.updatedById ?? $obj?.updatedBy?.id ?? null;
    this.approvedById = $obj?.approvedById ?? $obj?.approvedBy?.id ?? null;
    this.partnerId = $obj?.partnerId ?? $obj?.partner?.id ?? null;
    this.costCenterId = $obj?.costCenterId ?? $obj?.costCenter?.id ?? null;
    this.analysesSetBy = $obj?.analysesSetBy ? $obj.analysesSetBy : null;
    this.renewalDate = $obj?.renewalDate ? $obj.renewalDate : null;

    this.createdBy = $obj?.createdBy ? new User($obj.createdBy) : null;
    this.updatedBy = $obj?.updatedBy ? new User($obj.updatedBy) : null;
    this.approvedBy = $obj?.approvedBy ? new User($obj.approvedBy) : null;
    this.partner = $obj?.partner ? new User($obj.partner) : null;
    this.costCenter = $obj?.costCenter ? new Configuration($obj.costCenter) : null;
    this.country = $obj?.country ? new Country($obj.country) : null;

    this.createdAt = $obj?.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj?.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get isPending() {
    return this.status === CompanyStatus.PENDING;
  }
  get isAnalysis() {
    return this.status === CompanyStatus.ANALYSIS;
  }
  get isApproved() {
    return this.status === CompanyStatus.APPROVED;
  }

  get hasRitt() {
    return this.rittSubscribed;
  }
  get hasGuias() {
    return this.eGuidesSubscribed;
  }

  get addressComplete() {
    const addresses = [];

    if (this.address) {
      addresses.push(this.address);
    }
    if (this.postalCode) {
      addresses.push(this.postalCode);
    }
    if (this.establishment) {
      addresses.push(this.establishment);
    }

    return addresses.toString().replace(/,/g, ', ');
  }

  get statusDescription() {
    switch (this.status) {
      case CompanyStatus.PENDING: {
        return 'Pendente';
      }
      case CompanyStatus.ANALYSIS: {
        return 'Em Análise';
      }
      case CompanyStatus.APPROVED: {
        return 'Aprovado';
      }
      case CompanyStatus.CONSULT: {
        return 'Consulta';
      }
      case CompanyStatus.DELETED: {
        return 'Inativo';
      }
      default: {
        return 'Pendente';
      }
    }
  }

  get statusColor() {
    switch (this.status) {
      case CompanyStatus.PENDING: {
        return 'orange';
      }
      case CompanyStatus.CONSULT: {
        return 'blue';
      }
      case CompanyStatus.ANALYSIS: {
        return 'yellow';
      }
      case CompanyStatus.APPROVED: {
        return 'green';
      }
      case CompanyStatus.DELETED: {
        return 'red';
      }
      default: {
        return 'orange';
      }
    }
  }
}
