import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { AuthService } from '../service/auth.service';
import { filter, first, switchMap, takeUntil, takeWhile } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.currentUser;

    if (request.headers.has('no-token') || request.url.includes('.json')) {
      return next.handle(request);
    }

    if (currentUser && currentUser?.token) {
      if (
        currentUser.expiresIn &&
        moment(currentUser.expiresIn).isBefore(moment().add(15, 'minutes')) &&
        !this.authenticationService.isRefreshing
      ) {
        return this.authenticationService.refreshToken().pipe(
          first(),
          switchMap(() => {
            const cloned = request.clone({
              headers: request.headers.append('Authorization', `Bearer ${currentUser.token}`),
            });
            return next.handle(cloned);
          })
        );
      } else {
        if (!this.authenticationService.isRefreshing) {
          const cloned = request.clone({
            headers: request.headers.append('Authorization', `Bearer ${currentUser.token}`),
          });
          return next.handle(cloned);
        }

        return this.authenticationService.$isRefreshing.pipe(
          takeWhile((r) => r, true),
          filter((v) => {
            return v === false;
          }),
          switchMap(() => {
            const cloned = request.clone({
              headers: request.headers.append('Authorization', `Bearer ${currentUser.token}`),
            });
            return next.handle(cloned);
          })
        );
      }
    }
    return next.handle(request);
  }
}
